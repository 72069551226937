<template>
  <div class="ddj-postback">
    <!-- <div class="main-Title bgff"><h2>转化数据</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="list-filter mb10">
        <el-form :model="filter" label-width="80px" size="small">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="6" :xl="6">
              <el-form-item label="offer ID:" label-width="70px">
                <el-input
                  v-model="filter.offerId"
                  placeholder="Please enter offer ID"
                  class="mr10"
                ></el-input
              ></el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="6">
              <el-form-item label="Country:" label-width="60px">
                <el-select v-model="filter.country" placeholder="Please select" class="w100">
                  <el-option label="All" value="" />
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :value="item.countryCode"
                    :label="item.countryCode"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="6" :xl="4">
              <el-form-item label-width="0px">
                <el-button
                  type="primary"
                  size="mini"
                  @click="getList('filter')"
                  :loading="loading.list"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="listTableRef"
          class="w100"
          size="mini"
          border
          highlight-current-row
        >
          <el-table-column label="offer ID" prop="offerId"></el-table-column>
          <el-table-column label="Country" prop="country"></el-table-column>
          <el-table-column label="OS" prop="osVersion"></el-table-column>
          <el-table-column label="Price" prop="price"></el-table-column>
          <el-table-column label="上游名称" prop="advertiserName"></el-table-column>
          <el-table-column label="Click ID" prop="clickId"></el-table-column>
          <el-table-column label="回传时间" prop="createTime"></el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <div v-if="isShowPage" class="pagination-container pt-10" align="center">
          <el-pagination
            :page-size="pages.pageSize"
            :total="pagesTotal"
            background
            layout="total, prev, pager, next, jumper"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
  import ddjPostbackCon from '../../controllers/DDJ/v3/postback';
  export default {
    name: 'postback',
    ...ddjPostbackCon,
  };
</script>

<style></style>
