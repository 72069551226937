import { fetchPostbackPage } from '@/api/DDJ/v3/postBack';
import { fetchCountryList } from '@/api/DDJ/v3/country';
export default {
  components: {},
  data() {
    return {
      filter: {},
      options: {
        country: [],
      },
      list: [
        {
          offerId: '1',
        },
      ],
      loading: {
        list: false,
      },
      pages: {
        pageNum: 1,
        pageSize: 20,
      },
      pagesTotal: 0,
      isShowPage: false,
      rules: {},
    };
  },
  mounted() {
    this.getCountry();
    this.getList();
  },
  computed: {},
  methods: {
    getList(types) {
      if (types == 'filter') {
        this.pages.pageNum = 1;
        this.isShowPage = false;
      }
      let query = Object.assign(this.filter, this.pages);
      this.loading.list = true;
      fetchPostbackPage(query).then((res) => {
        console.log(res);
        let data = res.result;
        this.list = [];
        this.list = data.records;
        this.pagesTotal = data.total;
        this.loading.list = false;
        this.isShowPage = true;
      });
    },
    getCountry() {
      fetchCountryList().then((res) => {
        this.options.country = res.result;
      });
    },
    // 分页监听
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getList();
    },
  },
};
